<template>
  <div>404 Not found!</div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  layout: 'default',
  data() {
    return {
      items: [
        {
          text: 'Not found',
          disabled: false,
          href: '',
        },
      ],
    };
  },

  mounted() {
    this.updateBreadCrumb(this.items);
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb']),
  },
};
</script>
